export interface Config {
  API: string;
  REGISTRATION_URL: string;
  LOGIN_URL: string;
  FACEBOOK_APP_ID: string;
  GOOGLE_CLIENT_ID: string;
  GOOGLE_MEASUREMENT_ID: string;
  GOOGLE_UNIVERSAL_ANALYTICS_ID: string;
  MIXPANEL_TOKEN: string;
  META_PIXEL_ID: string;
  ENVIRONMENT: string;
  RELEASE: string;
}

export const {
  API,
  REGISTRATION_URL,
  LOGIN_URL,
  FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID,
  GOOGLE_MEASUREMENT_ID,
  GOOGLE_UNIVERSAL_ANALYTICS_ID,
  MIXPANEL_TOKEN,
  META_PIXEL_ID,
  ENVIRONMENT,
  RELEASE
} = process.env.CONFIG as unknown as Config;
