import React from 'react';
import ReactGA from 'react-ga';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import { IntlProvider } from 'react-intl';

import { getTranslationMessages } from '@/locales';

import { Language } from '@chew/common/constants/language';
import { GOOGLE_UNIVERSAL_ANALYTICS_ID } from '@chew/common/config';

import { metaPixelAnalytics } from '@/services/analytics/meta-pixel';

import '../styles/globals.css';

ReactGA.initialize(GOOGLE_UNIVERSAL_ANALYTICS_ID);

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  React.useEffect(() => {
    ReactGA.pageview(router.pathname);
    metaPixelAnalytics.trackEvent('PageView', {});

    const handleRouteChange = (url: string) => {
      ReactGA.pageview(url);
      metaPixelAnalytics.trackEvent('PageView', {});
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <React.Fragment>
      <IntlProvider locale={router.locale} messages={getTranslationMessages(router.locale as Language)}>
        <Component {...pageProps} />
      </IntlProvider>
    </React.Fragment>
  );
}

export default MyApp;
