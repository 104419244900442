import { Analytics } from '../types';

export class MetaPixelAnalytics<EP> implements Analytics<EP, never> {
  getCrossDomainParameters(): Record<string, string | undefined> {
    return {};
  }

  trackEvent<E extends keyof EP>(type: E, properties: EP[E]): void {
    if (!window.fbq) return;

    window.fbq('track', type as string, { event_type: type, event_properties: properties });
  }

  trackUser(): void {}

  setCurrentUser(): void {}
}
